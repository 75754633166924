import React from "react";
import ColoredDropDownSection from "../../../Common/CommonSections/ColoredDropDownSection/DropDownSection";

const cooperationOptions = [
    {
        title: "Build the product from scratch",
        description: `
            The experts of the Impressit team will guide you through the entire product development process, 
            from requirement analysis and design to implementation and maintenance. With our team’s dedication, 
            open professional communication, and cutting-edge technologies, we ensure that your software product
            meets your unique needs and specifications. Trust Impressit to bring your vision to life and deliver high-quality, 
            innovative software product development services.
        `,
    },
    {
        title: "Modernize or add new features to an existing software",
        description: (
            <>
                Do you see the need to transform your existing software into a
                modern, user-friendly solution?
                <br />
                <br />
                Our software product development company can help you add new
                features and functionality to your software product, ensuring
                that it meets the latest industry standards and trends. Whether
                it's upgrading the user interface, integrating new technologies,
                or optimizing performance, our experts have the skills and
                expertise to deliver the enhancements you need.
            </>
        ),
    },
    {
        title: "Migrate an existing product to the cloud",
        description: `
            We can help you seamlessly transition your product to the cloud, providing you with the scalability, 
            flexibility, and security you need. Our migration services include assessment, planning, execution,
            and post-migration support, ensuring a smooth and efficient process. With our cloud solutions,
            you can enhance the performance, accessibility, and cost-effectiveness of your software product. 
            Upgrade to the cloud and transform the way you do business with our reliable and innovative solutions.
        `,
    },
    {
        title: "Automate business processes",
        description: `
            Use our custom software product development company services to streamline your business operations
            and increase productivity with our business process automation solutions. Our team of experts can 
            help you identify repetitive tasks and automate them using advanced technologies. With our solutions, 
            you can reduce manual errors, save time and resources, and improve overall efficiency. Empower your 
            employees to focus on higher-value tasks and make data-driven decisions with our reliable and innovative solutions.
        `,
    },
    {
        title: "Hand over development to a new team",
        description: `
            Our team has the experience and knowledge to take over your existing product, ensuring a smooth transition
            and continuity of service. We will analyze your product's code and architecture, provide maintenance and support,
            and implement new features and functionality as needed. Focus on your core business, and let our software
            product development company handle the technical details with our customized and efficient solutions.
        `,
    },
];

const CooperationSection = () => (
    <ColoredDropDownSection
        title="Perfect cooperation for custom product development."
        description={`
                We are ready to step in at every stage of the development process — 
                whether you just have a business idea or a functioning product that needs improvement. 
                That is why our services are perfect if you need to:
            `}
        dropDownOptions={cooperationOptions}
    />
);

export default CooperationSection;
